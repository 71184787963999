import { useGetAutomation } from '~/lib/frontend/automations/composables/management'
import { isFetchError, useGetApiError } from '~/lib/frontend/common/composables/fetch'

export default defineNuxtRouteMiddleware(async (to) => {
  const automationId = to.params['aid'] as string
  const automation = useGetAutomation(automationId || '')
  const getError = useGetApiError()
  const logger = getLogger()

  try {
    const res = await automation.promise
    if (res.data.value?.automationId) {
      return undefined
    }

    throw res.error.value || new Error("Couldn't resolve automation")
  } catch (e) {
    const err = getError(e)
    if (isFetchError(err)) {
      const isNotFound = [404, 422].includes(err.statusCode || 1)
      const isForbidden = [403, 401].includes(err.statusCode || 1)

      if (isNotFound) {
        return abortNavigation(
          createApiError({
            statusCode: 404,
            message: 'Automation not found',
            statusMessage: 'AutomationNotFound'
          })
        )
      }

      if (isForbidden) {
        return abortNavigation(
          createApiError({
            statusCode: 403,
            message: 'You do not have access to the specified automation',
            statusMessage: 'NoAutomationAccess'
          })
        )
      }
    }

    logger.error(err)
    return abortNavigation(
      createApiError({
        statusCode: 500,
        message: `${err.message}`,
        statusMessage: 'UnexpectedError'
      })
    )
  }
})
